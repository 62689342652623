import React, { ReactElement } from "react"
import Header from '../components/header'
import Footer from "../components/footer";
import { aboutBannerImg, bannerHeight } from "../models/models";
import Layout from "../components/layout";

function About(): ReactElement {
    return (
        <>
            <Header page={'About'} />
            <Layout>
                <div className="relative w-full overflow-hidden" style={{ height: bannerHeight }}>
                    <img className="inset-0 absolute w-full" alt="banner_img" src={aboutBannerImg} />
                </div>
                <div className="py-2 px-2 md:py-10 md:px-64 text-md md:text-xl text-center">
                    <p className="py-4">Midnight Adventures has been making Classic Point & Click Adventure Games since 2014 with the release of the Mystery of Haunted Hollow series.</p>
                    <p className="py-4">We hope you enjoy our Adventures as each game is designed with a plot twist in mind for an epic finale!</p>
                    <p className="py-4">Thank you so much for playing and we hope you enjoy our Adventure Games!</p>
                </div>
            </Layout>
            <Footer />
        </>
    )
}

export default About
